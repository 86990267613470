import I18n from '../../../utilities/i18n';
import noFlowSvg from '../assets/svg/empty-state.svg';
import retryIcon from '../assets/svg/retry-icon.svg';
import { DEFAULT_FLOW_PREVIEW, ERROR_TEXT, RETRY_TEXT, NO_FLOW_SELECTED_TEXT } from '../../../constants';

/**
 * @fileoverview Class for rendering WhatsApp Flows Preview with loading and error states
 * @module WhatsAppFlowsPreview
 */

/**
 * Handles the rendering of WhatsApp Flows Preview in an iframe with loading and error states.
 * During development, the iframe URL can be overridden using localStorage.
 *
 * @class WhatsAppFlowsPreview
 * @example
 * const preview = new WhatsAppFlowsPreview(container, {
 *   orientation: 'portrait'
 * });
 * preview.render();
 */
class WhatsAppFlowsPreview {
	/**
	 * Creates an instance of WhatsAppFlowsPreview.
	 *
	 * @constructor
	 * @param {HTMLElement} container - The DOM element that will contain the preview
	 * @param {Object} [config={}] - Configuration object for the preview
	 * @param {string} [config.orientation='portrait'] - The orientation of the preview ('portrait' or 'landscape')
	 */
	constructor (container, config) {
		this.previewerContainer = '#previewer-container';
		this.container = container;
		this.config = config || {};
		this.i18n = new I18n(config.locale);
	}

	/**
	 * Creates and returns a loader element with spinner and loading message.
	 *
	 * @static
	 * @returns {HTMLElement} The loader DOM element
	 * @example
	 * const loader = WhatsAppFlowsPreview.createLoader();
	 * container.appendChild(loader);
	 */
	createLoader () {
		const loader = document.createElement('div');
		loader.className = 'iframe-overlay iframe-loader';
		loader.innerHTML = `
					<div class="spinner"></div>
					<p>${this.i18n.get('mobilepush_whatsapp.JSONMESSAGE_FLOWS_META_PREVIEW_LOADING')}</p>
			`;
		return loader;
	}

	/**
	 * Creates an error message element with a retry button.
	 *
	 * @private
	 * @returns {HTMLElement} The error message DOM element with retry functionality
	 */
	createErrorMessage () {
		const errorDiv = document.createElement('div');
		errorDiv.className = 'iframe-overlay iframe-error';
		errorDiv.innerHTML = `
			<div class="error-container">
				<img src =${noFlowSvg} />
				<p class="error-text">${ERROR_TEXT}</p>
				<p class="retry-text">${RETRY_TEXT}</p>
				<button class="retry-button">
				<img src =${retryIcon} />
				${this.i18n.get('mobilepush_whatsapp.JSONMESSAGE_FLOWS_META_PREVIEW_LOAD_FAILURE_RETRY')}</button>
			</div>
   		`;
		errorDiv.querySelector('.retry-button').addEventListener('click', () => this.render());
		return errorDiv;
	}

	/**
	 * Creates an default message element when any flow is not selected
	 *
	 * @private
	 * @returns {HTMLElement} The default message DOM element.
	 */
	createDefaultTextMessage () {
		const defaultDiv = document.createElement('div');
		const documentPreviewUrl = DEFAULT_FLOW_PREVIEW;
		defaultDiv.className = 'iframe-overlay iframe-default';
		// Apply position: relative to the defaultDiv element
		defaultDiv.style.position = 'relative';
		defaultDiv.innerHTML = `
			<div class="error-container">
				<img src="${documentPreviewUrl}">
				<p class="error-text">${NO_FLOW_SELECTED_TEXT}</p>
				<p class="retry-text">${this.i18n.get('jsonmessageblock.JSONMESSAGE_FLOWS_SELECT_FLOW_PREVIEW')}</p>
			</div>
		`;
		return defaultDiv;
	}

	/**
	 * Sets the styles for the iframe based on orientation.
	 *
	 * @private
	 * @param {HTMLIFrameElement} iframe - The iframe element to style
	 */
	static setIframeFlowsPreviewClass (iframe) {
		iframe.classList.add('meta-flows-preview');
	}

	/**
	 * Renders the WhatsApp Flows Preview with loading state and error handling.
	 * Clears any existing content and creates a new iframe with the preview.
	 *
	 * @public
	 * @returns {void}
	 * @example
	 * const preview = new WhatsAppFlowsPreview(container, config);
	 * preview.render();
	 */
	render () {
		const { container } = this;
		const { action } = this.config;
		const existingIframe = container.querySelector('iframe:not(.meta-flows-preview)');
		if (existingIframe) {
			existingIframe.classList.add('hidden');
		}
		const existingFlowsPreview = container.querySelector('iframe.meta-flows-preview');
		if (existingFlowsPreview) {
			existingFlowsPreview.remove();
		}
		container.style.position = 'relative';
		this.removeLoader();
		const loader = this.createLoader();
		container.appendChild(loader);

		const iframe = document.createElement('iframe');

		if (this.config?.channel === 'whatsapptemplate') {
			const urlStatus = this.config?.flowPreviewUrlStatus;
			const previewUrl = this.config?.flowPreviewUrl;
			if (urlStatus === 'success') {
				iframe.src = previewUrl;
			} else if (urlStatus === 'error') {
				this.removeLoader();
				container.appendChild(this.createErrorMessage());
			}
		} else {
			const previewUrl = action?.parameters?.previewUrl || '';
			iframe.src =  previewUrl;
			if (previewUrl === '') {
				this.removeLoader();
				const defaultPreviewContainer = this.createDefaultTextMessage();
				container.appendChild(defaultPreviewContainer);
				return;
			}
			const defaultTextMessage = container.querySelector('.createDefaultTextMessage');
			if (defaultTextMessage) {
				defaultTextMessage.remove();
			}
		}

		iframe.style.opacity = '0';

		iframe.onload = () => {
			if	(iframe.src !== '')	{
				this.removeLoader();
			}
			iframe.style.opacity = '1';
		};

		iframe.onerror = () => {
			this.removeLoader();
			container.appendChild(this.createErrorMessage());
		};

		container.appendChild(iframe);
		WhatsAppFlowsPreview.setIframeFlowsPreviewClass(iframe);
	}

	removeLoader () {
		const { container } = this;
		const loaders = container.querySelectorAll('.iframe-overlay.iframe-loader');
		loaders.forEach((loader) => {
			loader.remove();
		});
		const defaultLoaders = container.querySelectorAll('.iframe-overlay.iframe-default');
		defaultLoaders.forEach((defaultLoader) => {
			defaultLoader.remove();
		});
	}
}

export default WhatsAppFlowsPreview;
